import { addUserProperty } from '/services/analytics/heap';
import axios from 'axios';
import { getDeviceType } from '/utils/device-type';
import { getShinestyClientId } from '/services/analytics/shinesty';

const CONTROL_KEY = 'control';

const getAbTestPayload = (testKey) => {
	let clientId = getShinestyClientId();

	const deviceType = getDeviceType();

	return {
		clientId: clientId,
		deviceType: deviceType,
		key: testKey,
	};
};

const getTestVariant = async (testKey) => {
	const payload = getAbTestPayload(testKey);

	return axios
		.post(`${process.env.NEXT_PUBLIC_SHINESTY_ANALYTICS_PLATFORM}/ab-test-user`, payload)
		.then((res) => {
			if (res?.status === 200 && res.data) {
				if (res.data.assignedVariant && res.data.variantKey) {
					addUserProperty(testKey, res.data.variantKey);
					try {
						gtag('set', 'user_properties', {
							experiment_name: testKey,
							experiment_id: res.data.variantKey,
						});
					} catch (e) {
						console.error('Error setting user properties for Google Tag Manager:', e);
					}
				}

				return { error: false, key: res.data?.variantKey || CONTROL_KEY };
			}

			return { error: true, key: CONTROL_KEY };
		})
		.catch(handleError);
};

const getTestVariantServerside = async (config) => {
	return axios
		.post(`${process.env.NEXT_PUBLIC_SHINESTY_ANALYTICS_PLATFORM}/ab-test-user`, config)
		.then((res) => {
			return res.data;
		});
};

const handleError = () => {
	return { error: true, key: CONTROL_KEY };
};

module.exports = {
	getTestVariant,
	getTestVariantServerside,
};
