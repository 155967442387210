import axios from 'axios';
import { formatSearchspringResponse } from './formatter';

export const getCollectionProducts = async ({
	additionalFilters = [],
	isCollection = true,
	collectionHandle,
	page = 1,
	resultsPerPage = 48,
	selectedFacets = {},
	filters = {},
	sort = {},
	q = '',
}) => {
	const baseUrl = `https://${process.env.NEXT_PUBLIC_SEARCHSPRING_SITE_ID}.a.searchspring.io/api/search/search.json`;

	try {
		const params = new URLSearchParams();

		params.append('resultsFormat', 'native');
		params.append('siteId', process.env.NEXT_PUBLIC_SEARCHSPRING_SITE_ID);
		params.append('page', page.toString());
		params.append('resultsPerPage', resultsPerPage.toString());

		if (collectionHandle) {
			params.append('bgfilter.collection_handle', collectionHandle);
		}

		if (q) {
			params.append('q', q);
		}

		if (additionalFilters.length > 0) {
			additionalFilters.forEach((filter) => {
				params.append(filter.key, filter.value);
			});
		}

		if (isCollection) {
			params.append('bgfilter.mfield_denver_inventory_total_product_quantity.low', '1');
		}

		if (Object.keys(filters).length > 0) {
			Object.keys(filters).forEach((key) => {
				const filterValues = filters[key];
				if (Array.isArray(filterValues)) {
					filterValues.forEach((value) => {
						params.append(`filter.${key}`, value);
					});
				} else {
					params.append(`filter.${key}`, filterValues);
				}
			});
		}

		if (sort && sort.field) {
			params.append(`sort.${sort.field}`, sort.direction);
		}
		return axios
			.get(`${baseUrl}?${params.toString()}`)
			.then((response) => formatSearchspringResponse(response.data, selectedFacets));
	} catch (error) {
		console.error('Searchspring API Error:', error);
		throw error;
	}
};
