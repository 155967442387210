import { getFirstProperty } from '/utils/array';
import { getProductSticker } from '/services/static/product-sticker';

export function formatCollectionProduct(product) {
	if (!product) {
		return null;
	}

	const titles = product?.title?.split('|');
	const compareAtPrice =
		product?.pricing?.min < product?.pricing?.retailMin && product?.pricing?.retailMin;
	const quickAddValid =
		!product?.handle?.includes('build') &&
		!product?.handle?.includes('subscription') &&
		!product?.tags?.includes('feature: from');
	return {
		id: product.id,
		name: (titles && titles[0]) || '',
		handle: product.handle,
		href: `/products/${product.handle}`,
		description: product?.description || '',
		sticker: getProductSticker(product),
		showFrom: product?.tags?.includes('feature: from'),
		objectID: product.objectID,
		price: product?.pricing?.min,
		priceFull: compareAtPrice,
		imageSrc: getFirstProperty(product, 'images', 'imageSrc'),
		extraImages: product.extraImages,
		international_pricing: product.international_pricing,
		imageAlt: (titles && titles[0]) || 'shinesty product',
		productType: product.product_type,
		quickAddValid: quickAddValid,
	};
}
