import Button from '/components/Button';
import Carousel from '/components/Carousel';
import Clickable from '/components/Clickable';
import Icon from '/components/IconUpdated';
import Image from '/components/Image';
import Loading from '/components/Loading';
import PropTypes from 'prop-types';
import SizeGuide from '/components/size-guides/SizeGuide';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { createGid } from '../../utils/graph-ql';
import { getProductById } from '/services/products';
import { linesFromVariants } from '/utils/product';
import { useCartStore } from '/state/cart-context';
import { DISCOUNTED_LINE_PRICE, PRICE_TEST } from '/services/static/attribute-keys';
import Modal, { ModalBody } from '/components/Modal';
import { useEffect, useRef, useState } from 'react';

const VariantButton = ({ addToCart, product, variant, isMobile, closeModal }) => {
	const [addToCartLoading, setAddToCartLoading] = useState(false);

	const disabled = !variant?.dcInventory?.value || variant?.dcInventory?.value < 1;
	const title = variant.title == 'OSFA' ? 'Add To Cart' : variant.title.replace('US Jacket', '');

	const handleAddToCart = async () => {
		await addToCart(product, variant.id, variant.title, setAddToCartLoading);
		closeModal && closeModal();
	};

	return (
		<>
			{addToCartLoading ? (
				<div className="flex justify-center items-center min-w-[54px]">
					<Loading className="ml-2" height="30px" width="30px" />
				</div>
			) : (
				<Clickable
					className={classNames(
						'font-bold min-w-[54px] p-2 rounded border border-v2-brown bg-primary',
						isMobile ? 'bg-white border-gray-200 text-black' : 'bg-v2-off-white border-gray-200',
						disabled ? 'opacity-50' : 'hover:bg-v2-brown-darker hover:text-v2-off-white',
					)}
					disabled={disabled || addToCartLoading}
					onClick={handleAddToCart}>
					{title}
				</Clickable>
			)}
		</>
	);
};

VariantButton.propTypes = {
	addToCart: PropTypes.func,
	closeModal: PropTypes.func,
	isMobile: PropTypes.bool,
	product: PropTypes.object,
	setAddToCartLoading: PropTypes.func,
	variant: PropTypes.object,
};

const OfferCard = ({ offer, setSelectedOffer }) => {
	return (
		<div className="border border-gray-200 p-3 rounded-lg shadow-sm hover:shadow-md transition-shadow">
			<div className="flex gap-3 items-center">
				<Image
					alt={offer.heading}
					className="rounded-lg w-20 h-20 object-cover"
					src={offer.imageSrc}
					height={80}
					width={80}
				/>
				<div className="flex-1">
					<Typography variant="micro">{offer.copy}</Typography>
					<div className="mt-1">
						<Typography variant="micro">
							<span className="line-through">${offer.originalPrice}</span>{' '}
							<span className="font-bold text-primary">${offer.discountedPrice}</span>
						</Typography>
					</div>
				</div>
			</div>
			<div className="mt-2 text-center">
				<Clickable
					className="font-bold py-1 px-3 rounded border border-v2-brown bg-v2-off-white hover:bg-v2-brown-darker hover:text-v2-off-white text-sm w-full"
					onClick={() => setSelectedOffer(offer)}>
					View Options
				</Clickable>
			</div>
		</div>
	);
};

OfferCard.propTypes = {
	addToCart: PropTypes.func,
	offer: PropTypes.object,
	product: PropTypes.object,
	setSelectedOffer: PropTypes.func,
};

const CartOffers = ({ slidesToShow = 1 }) => {
	const [products, setProducts] = useState({});
	const [selectedOffer, setSelectedOffer] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const cart = useCartStore();
	const sliderRef = useRef();
	const imageSliderRef = useRef();
	const [showSizeGuide, setShowSizeGuide] = useState(false);

	const offers = [
		{
			id: '1',
			copy: 'Add a Maroon Mens Goldilocks hoodie for 62% off',
			heading: 'Special Offer',
			originalPrice: 79.99,
			discountedPrice: 29.99,
			imageSrc:
				'https://cdn.shopify.com/s/files/1/0234/5963/files/hoodie_562c1574-9943-4189-a0e5-839c8b387bef.jpg?v=1732057743&width=1536&quality=75',
			shopifyProductId: '7087208923205',
		},
		{
			id: '3',
			copy: 'Add a Gray Mens Goldilocks hoodie for 62% off',
			heading: 'Limited Time',
			originalPrice: 79.99,
			discountedPrice: 29.99,
			imageSrc:
				'https://cdn.shopify.com/s/files/1/0234/5963/files/hoodie_cad0e6e2-d59a-4a1a-aec0-d7abaa2e4809.jpg?v=1729703406&width=1536&quality=75',
			shopifyProductId: '7087208792133',
		},
		{
			id: '2',
			copy: 'Add a Blue Womens Goldilocks hoodie for 62% off',
			heading: 'Limited Time',
			originalPrice: 79.99,
			discountedPrice: 29.99,
			imageSrc:
				'https://cdn.shopify.com/s/files/1/0234/5963/files/hoodie_177dc89a-1c69-4394-a889-86387d362d2c.jpg?v=1729637810&width=1536&quality=75',
			shopifyProductId: '7087209283653',
		},
		{
			id: '4',
			copy: 'Add a Gray Womens Goldilocks hoodie for 62% off',
			heading: 'Limited Time',
			originalPrice: 79.99,
			discountedPrice: 29.99,
			imageSrc:
				'https://cdn.shopify.com/s/files/1/0234/5963/files/hoodie_d136b17e-daba-4d49-8dd6-57710112e27f.jpg?v=1729638854&width=1536&quality=75',
			shopifyProductId: '7087209218117',
		},
	];

	useEffect(() => {
		async function fetchProducts() {
			const productData = {};
			for (const offer of offers) {
				const product = await getProductById(offer.shopifyProductId);

				if (product) {
					productData[offer.shopifyProductId] = product;
				}
			}
			setProducts(productData);
		}
		fetchProducts();
	}, []);

	useEffect(() => {
		if (selectedOffer) {
			setIsModalOpen(true);
		}
	}, [selectedOffer]);

	const closeModal = () => {
		setIsModalOpen(false);
		setSelectedOffer(null);
	};

	const addToCart = async (product, variantId, selectedTitle, setAddToCartLoading) => {
		const attributes = [
			{
				key: DISCOUNTED_LINE_PRICE,
				value: String(selectedOffer.discountedPrice),
			},
			{
				key: PRICE_TEST,
				value: 'true',
			},
		];

		const line = {
			attributes,
			variantId,
			quantity: 1,
		};

		const lines = linesFromVariants([line]);

		const analyticsData = {
			price: product.prices[0],
			product: {
				id: createGid('Product', product.id),
				images: product.images,
				productType: product.productType,
				title: product.title,
				prices: product.prices,
				vendor: product.brand || 'Shinesty',
			},
			quantity: 1,
		};

		const variant = product.variants.find((variant) => {
			return variant.id && variant.id.toString() === variantId;
		});

		if (variant) {
			analyticsData.variantId = variant.id;
			analyticsData.variantSku = variant.sku;
			analyticsData.variantTitle = selectedTitle;
		}

		setAddToCartLoading(true);

		try {
			await cart.addItems({
				analytics: [analyticsData],
				lines: lines,
				success: () => {
					setAddToCartLoading(false);
				},
				error: () => {
					setAddToCartLoading(false);
				},
			});
		} catch (e) {
			setAddToCartLoading(false);
			console.log(e);
		}
	};

	const carouselSettings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: slidesToShow,
		slidesToScroll: 1,
		arrows: true,
		responsive: [
			{
				breakpoint: 768, // mobile breakpoint
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true,
				},
			},
			{
				breakpoint: 1400, // mobile breakpoint
				settings: {
					slidesToShow: slidesToShow,
					slidesToScroll: 1,
					dots: true,
				},
			},
		],
	};

	const imageCarouselSettings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		afterChange: (index) => setCurrentImageIndex(index),
	};

	const selectedProduct = selectedOffer ? products[selectedOffer.shopifyProductId] : null;

	const getStyleNumber = (product) => {
		if (!product || !product.tags) return null;

		const styleTag = product.tags.find((tag) => tag.startsWith('style:'));
		if (styleTag) {
			return styleTag.split('style:')[1].trim();
		}
		return null;
	};

	return (
		<>
			<div className="mb-6 text-v2-brown-darker">
				<div className="mb-2 ml-4 md:ml-2">
					<Typography variant="subhead-sm">We think you&apos;ll love these</Typography>
				</div>

				<Carousel ref={sliderRef} {...carouselSettings}>
					{offers.map((offer) => (
						<div key={offer.id} className="px-2">
							<OfferCard
								offer={offer}
								product={products[offer.shopifyProductId]}
								addToCart={addToCart}
								setSelectedOffer={setSelectedOffer}
							/>
						</div>
					))}
				</Carousel>
			</div>

			<Modal isOpen={isModalOpen} toggle={closeModal} size="large" locked={false} id="cart-offers">
				<ModalBody className="bg-v2-off-white p-4">
					{selectedOffer && selectedProduct && (
						<>
							<div className="flex justify-end mb-2">
								<Clickable onClick={closeModal} className="p-1 hover:bg-gray-100 rounded-full">
									<Icon name="close" width="24px" height="24px" />
								</Clickable>
							</div>

							<div className="flex flex-col md:flex-row gap-6">
								<div className="md:w-1/3">
									{selectedProduct.images && selectedProduct.images.length > 0 ? (
										<div className="relative">
											<Carousel ref={imageSliderRef} {...imageCarouselSettings}>
												{selectedProduct.images.map((image, index) => (
													<div key={index} className="px-1">
														<Image
															alt={image.altText || selectedProduct.title}
															className="rounded-lg w-full"
															src={image.src}
															height={400}
															width={400}
															objectFit="cover"
														/>
													</div>
												))}
											</Carousel>

											<div className="absolute bottom-2 right-2 bg-black/60 text-white px-2 py-1 rounded-full text-xs">
												{currentImageIndex + 1} / {selectedProduct.images.length}
											</div>
										</div>
									) : (
										<Image
											alt={selectedOffer.heading}
											className="rounded-lg w-full"
											src={selectedOffer.imageSrc}
											height={300}
											width={300}
											objectFit="cover"
										/>
									)}
								</div>
								<div className="md:w-2/3 grid">
									<Typography variant="heading-sm" className="mb-1">
										{selectedProduct.title}
									</Typography>
									<Typography variant="body" className="mb-4">
										{selectedOffer.copy}
									</Typography>

									<div className="mb-4">
										<Typography variant="body" className="font-bold">
											<span className="line-through">${selectedOffer.originalPrice}</span>{' '}
											<span className="text-primary">${selectedOffer.discountedPrice}</span>
										</Typography>
									</div>

									{selectedProduct.variants.length > 1 && (
										<div className="mb-2">
											<div className="flex items-center justify-between">
												<Typography variant="heading-xs">Select Size</Typography>
												{getStyleNumber(selectedProduct) && (
													<>
														<div className="md:hidden">
															<SizeGuide styleNumber={getStyleNumber(selectedProduct)} />
														</div>

														<div className="hidden md:block">
															<Button
																variant="link"
																title="View Size Guide"
																className="text-v2-brown-darker capitalize underline underline-offset-2"
																onClick={() => setShowSizeGuide(!showSizeGuide)}>
																Size Guide
															</Button>
														</div>
													</>
												)}
											</div>
										</div>
									)}

									{showSizeGuide && getStyleNumber(selectedProduct) && (
										<div className="hidden md:block mb-4 border border-gray-200 rounded-lg">
											<SizeGuide styleNumber={getStyleNumber(selectedProduct)} modal={false} />
										</div>
									)}

									<div className="flex flex-wrap gap-2">
										{selectedProduct.variants.map((variant, i) => (
											<VariantButton
												key={i}
												variant={variant}
												addToCart={addToCart}
												product={selectedProduct}
												isMobile={false}
												closeModal={closeModal}
											/>
										))}
									</div>
								</div>
							</div>

							<div className="mt-8 border-t border-gray-200 md:p-6">
								<Typography variant="heading-xs" component="h3" className="mb-4">
									Product Details
								</Typography>

								<ul className="space-y-4 mb-6">
									<li>
										<Typography variant="subtitle-sm" component="h4" className="mb-1">
											Stupid-Soft Stretch Fabric
										</Typography>
										<Typography variant="body">Ultra soft, ultra fancy fabric</Typography>
									</li>
									<li>
										<Typography variant="subtitle-sm" component="h4" className="mb-1">
											Materials
										</Typography>
										<Typography variant="body">
											This sueded, medium-weight, cotton-heavy, stretch fabric blend could double as
											a cloud
										</Typography>
									</li>
									<li>
										<Typography variant="subtitle-sm" component="h4" className="mb-1">
											Modern Flattering Fit
										</Typography>
										<Typography variant="body">
											Designed to look elevated but comfortable enough to sleep in.
										</Typography>
									</li>
									<li>
										<Typography variant="subtitle-sm" component="h4" className="mb-1">
											Love &apos;Em Or They&apos;re Free
										</Typography>
										<Typography variant="body">
											Designed to look elevated but comfortable enough to sleep in.
										</Typography>
									</li>
								</ul>

								<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
									<Image
										src="https://cdn.shinesty.com/2024-10-23/mens-lounge-hoodie-fabric-2.jpg?size=1536w"
										alt="Lounge Hoodie Fabric"
										className="rounded-lg"
										width={600}
										height={400}
										objectFit="cover"
									/>
									<Image
										src="https://cdn.shinesty.com/2024-10-23/mens-lounge-hoodie-feature-1.jpg?size=1536w"
										alt="Lounge Hoodie Feature"
										className="rounded-lg"
										width={600}
										height={400}
										objectFit="cover"
									/>
								</div>
							</div>
						</>
					)}
				</ModalBody>
			</Modal>
		</>
	);
};

CartOffers.propTypes = {
	slidesToShow: PropTypes.number,
};

export default CartOffers;
