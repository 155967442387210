import Typography from '/components/Typography';

import { formatMonetaryValue } from '/utils/format';
import { sortByOrder } from '/utils/common-utils';
import { v4 as uuidv4 } from 'uuid';

import {
	CAN_CHANGE_QUANTITY,
	DEFAULT_IMAGE,
	DISCOUNTED_LINE_PRICE,
	EXPECTED_CHILDREN_COUNT,
	FIRST_PAIR_IMAGE,
	FIRST_PAIR_IMAGES,
	FIRST_PAIR_TITLE,
	HIDE_QUANTITY,
	IS_HIDDEN,
	IS_SUBSCRIPTION,
	IS_SUBSCRIPTION_CHILD,
	IS_SUBSCRIPTION_PARENT,
	LINK_TO,
	MINI_CART_DATA,
	ORIGINAL_PRICE,
	PREPAID_TYPE,
	SUBSCRIPTION_FREQUENCY,
	SUBSCRIPTION_ID,
	SUBSCRIPTION_PRICE,
	SUBSCRIPTION_SUBTITLE,
	SUBSCRIPTION_TITLE,
	SUBSCRIPTION_TYPE,
} from '/services/static/attribute-keys';
import {
	DEFAULT_PRICE_LAUNDRY_SHEETS_DECIMAL,
	DEFAULT_PRICE_LAUNDRY_SHEETS_DECIMAL_SUBSCRIPTION,
	DEFAULT_PRICE_MENS_UNDERWEAR_DECIMAL,
	DEFAULT_PRICE_MENS_UNDERWEAR_LONG_DECIMAL,
	DEFAULT_PRICE_SOCKS_DECIMAL,
	DEFAULT_PRICE_SOCKS_RETAIL_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_BOYS_UNDERWEAR_MONTHLY_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_ANNUAL_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_ANNUAL_RETAIL_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_LONG_ANNUAL_RETAIL_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_LONG_QUARTERLY_RETAIL_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_MONTHLY_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_QUARTERLY_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_QUARTERLY_RETAIL_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_ANNUAL_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_ANNUAL_RETAIL_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_MONTHLY_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_QUARTERLY_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_QUARTERLY_RETAIL_DECIMAL,
	DEFAULT_PRICE_TSHIRTS_DECIMAL,
	DEFAULT_PRICE_TSHIRTS_RETAIL_DECIMAL,
	DEFAULT_PRICE_WOMENS_UNDERWEAR_DECIMAL,
} from '/services/static/default-prices';
import {
	SIZES_BOYS,
	SIZES_MENS_SUBSCRIPTION,
	SIZES_MENS_SUBSCRIPTION_TEES,
	SIZES_SOCKS,
	SIZES_WOMENS,
} from '/services/static/sizes';

const CARE_INSTRUCTIONS_MENS_DEFAULT = [
	'Machine Wash Delicate, with Like Colors.',
	'Do Not Bleach. Hang to Dry.',
	'Do Not Iron.',
];

const CARE_INSTRUCTIONS_WOMENS_DEFAULT = [
	'Machine Wash Delicate, with Like Colors.',
	'Tumble Dry Low.',
];

const SUBSCRIPTION_CONFIGS = [
	{
		gender: 'mens',
		subtitle: '95% MicroModal Ball Hammock® Underwear',
		title: 'Ball Hammock® Boxers',
	},
	{
		gender: 'womens',
		title: "Women's Underwear Styles",
	},
];

// TODO (subscription) rename flavor, used in signup flow
const FLAVORS = [
	{
		key: 'classic',
		description: 'No faces, no funny business. Solids, stripes, and the occasional pattern.',
	},
	{
		key: 'surprise',
		description: 'A delightful mix of patterns, solids, and crotch shots.',
	},
	{
		key: 'wild',
		description: 'Assorted patterns and crotch shots. Warning: People will stare.',
	},
];

const FREQUENCIES = [
	{
		billingText: 'Billed Annually',
		cardText: '1 Pair Delivered Every Month',
		discountText: 'Save',
		key: 'annually',
		label: '1 Pair Per Month for 12 Months',
		sellingPlan: process.env.NEXT_PUBLIC_SUBSCRIPTION_SELLING_PLAN_1_MONTH,
		sendText: "We'll send you 1 pair per month for 12 months",
		abHeader: 'Monthly subscription billed Yearly',
		abCardText: (
			<Typography component="p" variant="body">
				<span className="font-bold">One</span> pair of underwear delivered every month, billed
				yearly. You save 49% per pair.
			</Typography>
		),
	},
	{
		billingText: 'Billed Monthly',
		cardText: '1 Pair Delivered Every Month',
		discountText: 'Save',
		key: 'monthly',
		label: '1 Every 1 Month',
		sellingPlan: process.env.NEXT_PUBLIC_SUBSCRIPTION_SELLING_PLAN_1_MONTH,
		sendText: "We'll send you 1 pair per month",
		abHeader: 'Monthly Subscription',
		abCardText: 'One pair of underwear delivered every month and save 39% off each pair',
	},
	{
		billingText: 'Billed Quarterly',
		cardText: '3 Pairs Delivered Every 3 Months',
		discountText: 'Save',
		key: 'quarterly',
		label: '3 Every 3 Months',
		sellingPlan: process.env.NEXT_PUBLIC_SUBSCRIPTION_SELLING_PLAN_3_MONTH,
		sendText: "We'll send you 3 pairs every 3 months",
		abHeader: 'Quarterly Subscription',
		abCardText:
			'Three pairs of underwear delivered every 3 months, you pick your first 3 pairs, and save 47%',
	},
	{
		billingText: 'Billed Bi Monthly',
		cardText: '1 Pack Delivered Every 2 Months',
		discountText: 'Save',
		key: 'bimonthly',
		label: '1 Every 2 Months',
		sellingPlan: process.env.NEXT_PUBLIC_SUBSCRIPTION_SELLING_PLAN_3_MONTH,
		sendText: "We'll send you 1 box every 2 months",
		abHeader: 'Bi-monthly Subscription',
		// abCardText:
		// 	'Three pairs of underwear delivered every 3 months, you pick your first 3 pairs, and save 47%',
	},
];

const LANDING_PAGES = {
	couples: '/products/monthly-thong-subscription',
	laundrySheets: '/products/boulder-breeze-eco-laundry-detergent-strips',
	mens: '/products/monthly-boxer-subscription',
	socks: '/products/sock-subscription',
	womens: '/products/couples-underwear-subscription',
};

/**
 * parent variants are products that exist in production for couples & single subscriptions at a specific price point
 */
const PARENT_VARIANTS = [
	// thong + thong monthly
	// COMMENTING OUT BECAUSE THONG IS THE SAME PRICE AS WOMENS NOW (MAC, July 31)
	// {
	// 	id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NTAxMDg4NQ==',
	// 	key: 'couples2198',
	// 	productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMxMDMxNzM=',
	// 	sku: '20000-0009-OSFA',
	// 	title: 'Matching Couples Subscription',
	// },

	// womens + thong monthly
	// COMMENTING OUT BECAUSE THONG IS THE SAME PRICE AS WOMENS NOW (MAC, July 31)
	// {
	// 	id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NDc4MTUwOQ==',
	// 	key: 'couples2398',
	// 	productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTI5MDY1NjU=',
	// 	sku: '20000-0006-OSFA',
	// 	title: 'Matching Couples Subscription',
	// },

	// womens + womens monthly
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NDY4MzIwNQ==',
		key: 'couples3198',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTI4MDgyNjE=',
		sku: '20000-0004-OSFA',
		title: 'Matching Couples Subscription',
	},

	// thong + mens
	// COMMENTING OUT BECAUSE THONG IS THE SAME PRICE AS WOMENS NOW (MAC, July 31)
	// {
	// 	id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NDkxMjU4MQ==',
	// 	key: 'couples2898',
	// 	productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMwMDQ4Njk=',
	// 	sku: '20000-0008-OSFA',
	// 	title: 'Matching Couples Subscription',
	// },

	// womens + mens
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NDgxNDI3Nw==',
		key: 'couples3398',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTI5MzkzMzM=',
		sku: '20000-0005-OSFA',
		title: 'Matching Couples Subscription',
	},
	// mens + mens
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NDg3OTgxMw==',
		key: 'couples3598',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTI5NzIxMDE=',
		sku: '20000-0007-OSFA',
		title: 'Matching Couples Subscription',
	},

	// thong single
	// COMMENTING OUT BECAUSE THONG IS THE SAME PRICE AS WOMENS NOW (MAC, July 31)
	// {
	// 	id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NDcxNTk3Mw==',
	// 	key: 'single1099',
	// 	productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTI4NDEwMjk=',
	// 	sku: '20000-0003-OSFA',
	// 	title:
	// 		'Monthly Underwear Subscription | Free Shipping, New Prints Monthly, Member-Only Savings',
	// },

	// womens single
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NDY1MDQzNw==',
		key: 'single1599',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTI3NzU0OTM=',
		sku: '20000-0001-OSFA',
		title:
			'Monthly Underwear Subscription | Free Shipping, New Prints Monthly, Member-Only Savings',
	},
	// mens single
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NDc0ODc0MQ==',
		key: 'single1999',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTI4NzM3OTc=',
		sku: '20000-0002-OSFA',
		title:
			'Monthly Underwear Subscription | Free Shipping, New Prints Monthly, Member-Only Savings',
	},
	// womens cooling single
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NTA0MzY1Mw==',
		key: 'single2898',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMxMzU5NDE=',
		sku: '20004-0003-OSFA',
		title:
			'Monthly Underwear Subscription | Free Shipping, New Prints Monthly, Member-Only Savings',
	},
	// mens cooling single
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NDk0NTM0OQ==',
		key: 'single3498',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMwMzc2Mzc=',
		sku: '20004-0001-OSFA',
		title:
			'Monthly Underwear Subscription | Free Shipping, New Prints Monthly, Member-Only Savings',
	},
	// mens quarterly
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NDk3ODExNw==',
		key: 'single4797',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMwNzA0MDU=',
		sku: '20004-0002-OSFA',
		title:
			'Monthly Underwear Subscription | Free Shipping, New Prints Monthly, Member-Only Savings',
	},

	// thong annual
	// COMMENTING OUT BECAUSE THONG IS THE SAME PRICE AS WOMENS NOW (MAC, July 31)
	// {
	// 	id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NTE0MTk1Nw==',
	// 	key: 'single10999',
	// 	productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMyMzQyNDU=',
	// 	sku: '20012-0003-OSFA',
	// 	title:
	// 		'Monthly Underwear Subscription | Free Shipping, New Prints Monthly, Member-Only Savings',
	// },

	// womens annual
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NTA3NjQyMQ==',
		key: 'single15696',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMxNjg3MDk=',
		sku: '20012-0001-OSFA',
		title:
			'Monthly Underwear Subscription | Free Shipping, New Prints Monthly, Member-Only Savings',
	},
	// mens annual
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NTEwOTE4OQ==',
		key: 'single17599',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMyMDE0Nzc=',
		sku: '20012-0002-OSFA',
		title:
			'Monthly Underwear Subscription | Free Shipping, New Prints Monthly, Member-Only Savings',
	},

	// laundry sheets
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDQ3ODA3MTA5NTM2NQ==',
		key: 'single1599',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDU2OTUwMDIzNzg5Mw==',
		sku: '20002-0001-OSFA',
		title: 'Bi-Monthly Laundry Sheet Subscription | Free Shipping, New Scents, Member-Only Savings',
	},
];

const SUBSCRIPTIONS = [
	{
		bullets: [
			'3x softer than cotton',
			'Anti-muffin top non-elastic waistband',
			'Mid rise with medium coverage',
		],
		careInstructions: CARE_INSTRUCTIONS_WOMENS_DEFAULT,
		collections: {
			default: 'monthly-bikini-underwear-subscription',
			surprise: 'monthly-bikini-underwear-subscription',
		},
		frequencies: [
			{
				key: 'annually',
				price: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_ANNUAL_DECIMAL,
				priceRegular: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_ANNUAL_RETAIL_DECIMAL,
				priceModifier: 'YR',
				title: 'Annual Bikini Subscription',
			},
			{
				key: 'monthly',
				price: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_MONTHLY_DECIMAL,
				priceRegular: DEFAULT_PRICE_WOMENS_UNDERWEAR_DECIMAL,
				priceModifier: 'MO',
				title: 'Monthly Bikini Subscription',
			},
			// Removing new quarterly signups until later date per Melinda 2022-07-13
			// {
			// 	key: 'quarterly',
			// 	price: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_QUARTERLY_DECIMAL,
			// 	priceRegular: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_QUARTERLY_RETAIL_DECIMAL,
			// 	priceModifier: 'QTR',
			// 	title: 'Quarterly Bikini Subscription',
			// },
		],
		gender: 'womens',
		iconKeyOutline: 'underwearBikiniOutline',
		iconKeyOutlineWhite: 'underwearBikiniOutlineWhite',
		iconKeySolid: 'underwearBikiniFilled',
		imagePlaceholderSrc: 'https://cdn.shinesty.com/2022-06-21/bikini-sketch.png',
		imagePlaceholderWhiteSrc: 'https://cdn.shinesty.com/2022-07-20/bikini-sketch-white.png',
		isMatching: true,
		landingPage: LANDING_PAGES.womens,
		material: '95% Modal, 5% Spandex',
		name: 'Bikini',
		nameMedium: 'Bikini',
		nameShort: 'Bikini',
		productType: 'Bikini',
		sizes: SIZES_WOMENS,
		styleNumber: '10096',
	},
	{
		bullets: [
			'3x softer than cotton',
			'Fun, kid appropriate prints only',
			'Sizes 2T-16',
			"Pouch looks just like dad's",
		],
		careInstructions: CARE_INSTRUCTIONS_MENS_DEFAULT,
		collections: {
			default: 'boys-underwear',
		},
		flavors: FLAVORS,
		frequencies: [
			{
				key: 'annually',
				price: DEFAULT_PRICE_SUBSCRIPTION_BOYS_UNDERWEAR_MONTHLY_DECIMAL,
				priceRegular: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_ANNUAL_RETAIL_DECIMAL,
				priceModifier: 'YR',
				title: 'Annual Boxer Subscription',
			},
			{
				key: 'monthly',
				price: DEFAULT_PRICE_SUBSCRIPTION_BOYS_UNDERWEAR_MONTHLY_DECIMAL,
				priceRegular: DEFAULT_PRICE_MENS_UNDERWEAR_DECIMAL,
				priceModifier: 'MO',
				title: 'Monthly Boys Boxer Subscription',
			},
		],
		gender: 'mens',
		iconKeyOutline: 'underwearBoysOutline',
		iconKeyOutlineWhite: 'underwearBoysOutline',
		iconKeySolid: 'underwearBoysFilled',
		imagePlaceholderSrc: 'https://cdn.shinesty.com/2022-06-21/boxerwfly-sketch.png',
		imagePlaceholderWhiteSrc: 'https://cdn.shinesty.com/2022-07-20/boxerwfly-sketch-white.png',
		img: 'https://cdn.shinesty.com/2022-06-22/with-fly-line.svg',
		isMatching: false,
		selectedImg: 'https://cdn.shinesty.com/2022-06-22/with-fly-filled.svg',
		landingPage: LANDING_PAGES.mens,
		material: '95% Modal, 5% Spandex',
		name: 'Boys Ball Hammock® Boxers',
		nameMedium: 'Boys Boxers',
		nameShort: 'Boys Boxers',
		productType: 'Boys Underwear',
		sizes: SIZES_BOYS,
		styleNumber: '10071',
	},
	{
		bullets: [
			'The gold standard',
			'6" inseam',
			'3x softer than cotton',
			'With Ball Hammock® Pouch',
		],
		careInstructions: CARE_INSTRUCTIONS_MENS_DEFAULT,
		collections: {
			classic: 'monthly-boxer-subscription-classic-options',
			surprise: 'monthly-boxer-subscription-surprise-options',
			wild: 'monthly-boxer-subscription-wild-options',
		},
		flavors: FLAVORS,
		frequencies: [
			{
				key: 'annually',
				price: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_ANNUAL_DECIMAL,
				priceRegular: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_ANNUAL_RETAIL_DECIMAL,
				priceModifier: 'YR',
				title: 'Annual Boxer Subscription',
			},
			{
				key: 'monthly',
				price: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_MONTHLY_DECIMAL,
				priceRegular: DEFAULT_PRICE_MENS_UNDERWEAR_DECIMAL,
				priceModifier: 'MO',
				title: 'Monthly Boxer Subscription',
			},
			{
				key: 'quarterly',
				price: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_QUARTERLY_DECIMAL,
				priceRegular: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_QUARTERLY_RETAIL_DECIMAL,
				priceModifier: 'QTR',
				title: 'Quarterly Boxer Subscription',
			},
		],
		gender: 'mens',
		iconKeyOutline: 'underwearBoxerOutline',
		iconKeyOutlineWhite: 'underwearBoxerOutlineWhite',
		iconKeySolid: 'underwearBoxerFilled',
		imagePlaceholderSrc: 'https://cdn.shinesty.com/2022-06-21/standardboxer-sketch.png',
		imagePlaceholderWhiteSrc: 'https://cdn.shinesty.com/2022-07-20/standardboxer-sketch-white.png',
		img: 'https://cdn.shinesty.com/2022-06-22/standard-line.svg',
		isMatching: true,
		selectedImg: 'https://cdn.shinesty.com/2022-06-22/standard-filled.svg',
		landingPage: LANDING_PAGES.mens,
		material: '95% Modal, 5% Spandex',
		name: 'Ball Hammock® Boxer Briefs',
		nameMedium: 'Boxer',
		nameShort: 'Boxer Brief',
		productType: 'Boxers',
		sizes: SIZES_MENS_SUBSCRIPTION,
		styleNumber: '10071',
	},
	{
		bullets: [
			'Mid rise boy short with full coverage',
			'Anti-muffin top non-elastic waistband',
			'3x softer than cotton',
		],
		careInstructions: CARE_INSTRUCTIONS_WOMENS_DEFAULT,
		collections: {
			default: 'monthly-boyshort-underwear-subscription',
			surprise: 'monthly-boyshort-underwear-subscription',
		},
		frequencies: [
			{
				key: 'annually',
				price: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_ANNUAL_DECIMAL,
				priceRegular: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_ANNUAL_RETAIL_DECIMAL,
				priceModifier: 'YR',
				title: 'Annual Boyshort Subscription',
			},
			{
				key: 'monthly',
				price: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_MONTHLY_DECIMAL,
				priceRegular: DEFAULT_PRICE_WOMENS_UNDERWEAR_DECIMAL,
				priceModifier: 'MO',
				title: 'Monthly Boyshort Subscription',
			},
			// Removing new quarterly signups until later date per Melinda 2022-07-13
			// {
			// 	key: 'quarterly',
			// 	price: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_QUARTERLY_DECIMAL,
			// 	priceRegular: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_QUARTERLY_RETAIL_DECIMAL,
			// 	priceModifier: 'QTR',
			// 	title: 'Quarterly Boyshort Subscription',
			// },
		],
		gender: 'womens',
		iconKeyOutline: 'underwearBoyshortOutline',
		iconKeyOutlineWhite: 'underwearBoyshortOutlineWhite',
		iconKeySolid: 'underwearBoyshortFilled',
		imagePlaceholderSrc: 'https://cdn.shinesty.com/2022-06-21/boyshort-sketch.png',
		imagePlaceholderWhiteSrc: 'https://cdn.shinesty.com/2022-07-20/boyshort-sketch-white.png',
		isMatching: true,
		landingPage: LANDING_PAGES.womens,
		material: '95% Modal, 5% Spandex',
		name: 'Boyshort',
		nameMedium: 'Boyshort',
		nameShort: 'Boyshort',
		productType: 'Boyshort',
		sizes: SIZES_WOMENS,
		styleNumber: '10096',
	},
	{
		bullets: [
			'Slight booty with medium coverage and mid-rise fit',
			'3x softer than cotton',
			'No-itch flatlock stitching reduces bulk',
		],
		careInstructions: CARE_INSTRUCTIONS_WOMENS_DEFAULT,
		collections: {
			default: 'monthly-cheeky-subscription',
			surprise: 'monthly-cheeky-subscription',
		},
		frequencies: [
			{
				key: 'annually',
				price: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_ANNUAL_DECIMAL,
				priceRegular: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_ANNUAL_RETAIL_DECIMAL,
				priceModifier: 'YR',
				title: 'Annual Cheeky Subscription',
			},
			{
				key: 'monthly',
				price: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_MONTHLY_DECIMAL,
				priceRegular: DEFAULT_PRICE_WOMENS_UNDERWEAR_DECIMAL,
				priceModifier: 'MO',
				title: 'Monthly Cheeky Subscription',
			},
			// Removing new quarterly signups until later date per Melinda 2022-07-13
			// {
			// 	key: 'quarterly',
			// 	price: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_QUARTERLY_DECIMAL,
			// 	priceRegular: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_QUARTERLY_RETAIL_DECIMAL,
			// 	priceModifier: 'QTR',
			// 	title: 'Quarterly Cheeky Subscription',
			// },
		],
		gender: 'womens',
		iconKeyOutline: 'underwearCheekyOutline',
		iconKeyOutlineWhite: 'underwearCheekyOutlineWhite',
		iconKeySolid: 'underwearCheekyFilled',
		imagePlaceholderSrc: 'https://cdn.shinesty.com/2022-06-21/cheeky-sketch.png',
		imagePlaceholderWhiteSrc: 'https://cdn.shinesty.com/2022-07-20/cheeky-sketch-white.png',
		isMatching: true,
		landingPage: LANDING_PAGES.womens,
		material: '95% Modal, 5% Spandex',
		name: 'Cozy Cheeky',
		nameMedium: 'Cheeky',
		nameShort: 'Cheeky',
		productType: 'Cheeky',
		sizes: SIZES_WOMENS,
		styleNumber: '10096',
	},
	{
		bullets: ['Front fly', '0" inseam', '3x softer than cotton', 'With Ball Hammock® Pouch'],
		careInstructions: CARE_INSTRUCTIONS_MENS_DEFAULT,
		collections: {
			classic: 'monthly-boxer-subscription-classic-brief-options',
			surprise: 'monthly-boxer-subscription-surprise-brief-options',
			wild: 'monthly-boxer-subscription-wild-brief-options',
		},
		flavors: FLAVORS,
		frequencies: [
			{
				key: 'annually',
				price: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_ANNUAL_DECIMAL,
				priceRegular: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_ANNUAL_RETAIL_DECIMAL,
				priceModifier: 'YR',
				title: 'Annual Brief Subscription',
			},
			{
				key: 'monthly',
				price: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_MONTHLY_DECIMAL,
				priceRegular: DEFAULT_PRICE_MENS_UNDERWEAR_DECIMAL,
				priceModifier: 'MO',
				title: 'Monthly Brief Subscription',
			},
			// Removing new quarterly signups until later date per Melinda 2022-07-13
			// {
			// 	key: 'quarterly',
			// 	price: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_QUARTERLY_DECIMAL,
			// 	priceRegular: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_QUARTERLY_RETAIL_DECIMAL,
			// 	priceModifier: 'QTR',
			// 	title: 'Quarterly Brief Subscription',
			// },
		],
		gender: 'mens',
		iconKeyOutline: 'underwearBoxerBriefOutline',
		iconKeyOutlineWhite: 'underwearBoxerBriefOutlineWhite',
		iconKeySolid: 'underwearBoxerBriefFilled',
		imagePlaceholderSrc: 'https://cdn.shinesty.com/2022-06-21/brief-sketch.png',
		imagePlaceholderWhiteSrc: 'https://cdn.shinesty.com/2022-07-20/brief-sketch-white.png',
		img: 'https://cdn.shinesty.com/2022-06-22/brief-line.svg',
		isMatching: true,
		selectedImg: 'https://cdn.shinesty.com/2022-06-22/brief-filled.svg',
		landingPage: LANDING_PAGES.mens,
		material: '95% Modal, 5% Spandex',
		name: 'Ball Hammock® Briefs',
		nameMedium: 'Brief',
		nameShort: 'Briefs',
		productType: 'Boxers - Brief',
		sizes: SIZES_MENS_SUBSCRIPTION,
		styleNumber: '10071',
	},
	{
		bullets: ['Front fly', '6" inseam', '3x softer than cotton', 'With Ball Hammock® Pouch'],
		careInstructions: CARE_INSTRUCTIONS_MENS_DEFAULT,
		collections: {
			classic: 'monthly-boxer-subscription-classic-fly-options',
			surprise: 'monthly-boxer-subscription-surprise-fly-options',
			wild: 'monthly-boxer-subscription-wild-fly-options',
		},
		flavors: FLAVORS,
		frequencies: [
			{
				key: 'annually',
				price: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_ANNUAL_DECIMAL,
				priceRegular: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_ANNUAL_RETAIL_DECIMAL,
				priceModifier: 'YR',
				title: 'Annual Boxer Subscription',
			},
			{
				key: 'monthly',
				price: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_MONTHLY_DECIMAL,
				priceRegular: DEFAULT_PRICE_MENS_UNDERWEAR_DECIMAL,
				priceModifier: 'MO',
				title: 'Monthly Boxer Subscription',
			},
			{
				key: 'quarterly',
				price: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_QUARTERLY_DECIMAL,
				priceRegular: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_QUARTERLY_RETAIL_DECIMAL,
				priceModifier: 'QTR',
				title: 'Quarterly Boxer Subscription',
			},
		],
		gender: 'mens',
		iconKeyOutline: 'underwearBoxerFlyOutline',
		iconKeyOutlineWhite: 'underwearBoxerFlyOutlineWhite',
		iconKeySolid: 'underwearBoxerFlyFilled',
		imagePlaceholderSrc: 'https://cdn.shinesty.com/2022-06-21/boxerwfly-sketch.png',
		imagePlaceholderWhiteSrc: 'https://cdn.shinesty.com/2022-07-20/boxerwfly-sketch-white.png',
		img: 'https://cdn.shinesty.com/2022-06-22/with-fly-line.svg',
		isMatching: true,
		selectedImg: 'https://cdn.shinesty.com/2022-06-22/with-fly-filled.svg',
		landingPage: LANDING_PAGES.mens,
		material: '95% Modal, 5% Spandex',
		name: 'Ball Hammock® Boxer Briefs w/ Fly',
		nameMedium: 'Boxer w/ Fly',
		nameShort: 'Boxer Brief w/ Fly',
		productType: 'Boxers - Fly',
		sizes: SIZES_MENS_SUBSCRIPTION,
		styleNumber: '10071',
	},
	{
		bullets: ['Front fly', '8" inseam', '3x softer than cotton', 'With Ball Hammock® Pouch'],
		careInstructions: CARE_INSTRUCTIONS_MENS_DEFAULT,
		collections: {
			classic: 'monthly-boxer-subscription-classic-long-options',
			surprise: 'monthly-boxer-subscription-surprise-long-options',
			wild: 'monthly-boxer-subscription-wild-long-options',
		},
		flavors: FLAVORS,
		frequencies: [
			{
				key: 'annually',
				price: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_ANNUAL_DECIMAL,
				priceRegular: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_LONG_ANNUAL_RETAIL_DECIMAL,
				priceModifier: 'YR',
				title: 'Annual Long Boxer Subscription',
			},
			{
				key: 'monthly',
				price: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_MONTHLY_DECIMAL,
				priceRegular: DEFAULT_PRICE_MENS_UNDERWEAR_LONG_DECIMAL,
				priceModifier: 'MO',
				title: 'Monthly Long Boxer Subscription',
			},
			{
				key: 'quarterly',
				price: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_QUARTERLY_DECIMAL,
				priceRegular: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_LONG_QUARTERLY_RETAIL_DECIMAL,
				priceModifier: 'QTR',
				title: 'Quarterly Long Boxer Subscription',
			},
		],
		gender: 'mens',
		iconKeyOutline: 'underwearBoxerLongOutline',
		iconKeyOutlineWhite: 'underwearBoxerLongOutlineWhite',
		iconKeySolid: 'underwearBoxerLongFilled',
		imagePlaceholderSrc: 'https://cdn.shinesty.com/2022-06-21/long-sketch.png',
		imagePlaceholderWhiteSrc: 'https://cdn.shinesty.com/2022-07-20/long-sketch-white.png',
		img: 'https://cdn.shinesty.com/2022-06-22/long-line.svg',
		isMatching: true,
		selectedImg: 'https://cdn.shinesty.com/2022-06-22/long-filled.svg',
		landingPage: LANDING_PAGES.mens,
		material: '95% Modal, 5% Spandex',
		name: 'Long-Legged Ball Hammock® Boxer Briefs',
		nameMedium: 'Long Boxer',
		nameShort: 'Long Boxer Brief w/ Fly',
		productType: 'Boxers - Long - Fly',
		sizes: SIZES_MENS_SUBSCRIPTION,
		styleNumber: '10071',
	},
	{
		bullets: ['Front fly', '3.5" inseam', '3x softer than cotton', 'With Ball Hammock® Pouch'],
		careInstructions: CARE_INSTRUCTIONS_MENS_DEFAULT,
		collections: {
			classic: 'monthly-boxer-subscription-classic-trunk-options',
			surprise: 'monthly-boxer-subscription-surprise-trunk-options',
			wild: 'monthly-boxer-subscription-wild-trunk-options',
		},
		flavors: FLAVORS,
		frequencies: [
			{
				key: 'annually',
				price: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_ANNUAL_DECIMAL,
				priceRegular: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_ANNUAL_RETAIL_DECIMAL,
				priceModifier: 'YR',
				title: 'Annual Trunks Subscription',
			},
			{
				key: 'monthly',
				price: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_MONTHLY_DECIMAL,
				priceRegular: DEFAULT_PRICE_MENS_UNDERWEAR_DECIMAL,
				priceModifier: 'MO',
				title: 'Monthly Trunks Subscription',
			},
			// Removing new quarterly signups until later date per Melinda 2022-07-13
			// {
			// 	key: 'quarterly',
			// 	price: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_QUARTERLY_DECIMAL,
			// 	priceRegular: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_QUARTERLY_RETAIL_DECIMAL,
			// 	priceModifier: 'QTR',
			// 	title: 'Quarterly Trunks Subscription',
			// },
		],
		gender: 'mens',
		iconKeyOutline: 'underwearBoxerTrunksOutline',
		iconKeyOutlineWhite: 'underwearBoxerTrunksOutlineWhite',
		iconKeySolid: 'underwearBoxerTrunksFilled',
		imagePlaceholderSrc: 'https://cdn.shinesty.com/2022-06-21/trunk-sketch.png',
		imagePlaceholderWhiteSrc: 'https://cdn.shinesty.com/2022-07-20/trunk-sketch-white.png',
		img: 'https://cdn.shinesty.com/2022-06-22/trunks-line.svg',
		isMatching: true,
		selectedImg: 'https://cdn.shinesty.com/2022-06-22/trunks-filled.svg',
		landingPage: LANDING_PAGES.mens,
		material: '95% Modal, 5% Spandex',
		name: 'Ball Hammock® Trunks',
		nameMedium: 'Trunk',
		nameShort: 'Trunks',
		productType: 'Boxers - Trunk',
		sizes: SIZES_MENS_SUBSCRIPTION,
		styleNumber: '10071',
	},
	{
		bullets: ['Silky Barely There Feel', 'Zero Panty Lines', 'Mid rise with low coverage'],
		careInstructions: CARE_INSTRUCTIONS_WOMENS_DEFAULT,
		collections: {
			default: 'monthly-thong-subscription',
			surprise: 'monthly-thong-subscription',
		},
		frequencies: [
			{
				key: 'annually',
				price: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_ANNUAL_DECIMAL,
				priceRegular: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_ANNUAL_RETAIL_DECIMAL,
				priceModifier: 'YR',
				title: 'Annual Thong Subscription',
			},
			{
				key: 'monthly',
				price: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_MONTHLY_DECIMAL,
				priceRegular: DEFAULT_PRICE_WOMENS_UNDERWEAR_DECIMAL,
				priceModifier: 'MO',
				title: 'Monthly Thong Subscription',
			},
			{
				key: 'quarterly',
				price: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_QUARTERLY_DECIMAL,
				priceRegular: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_QUARTERLY_RETAIL_DECIMAL,
				priceModifier: 'QTR',
				title: 'Quarterly Thong Subscription',
			},
		],
		gender: 'womens',
		iconKeyOutline: 'underwearThongOutline',
		iconKeyOutlineWhite: 'underwearThongOutlineWhite',
		iconKeySolid: 'underwearThongFilled',
		imagePlaceholderSrc: 'https://cdn.shinesty.com/2022-06-21/thong-sketch.png',
		imagePlaceholderWhiteSrc: 'https://cdn.shinesty.com/2022-07-20/thong-sketch-white.png',
		isMatching: true,
		landingPage: LANDING_PAGES.womens,
		material: '75% Nylon, 25% Spandex',
		name: 'Seamless Thong',
		nameMedium: 'Thong',
		nameShort: 'Seamless Thongs',
		productType: 'Thongs',
		sizes: SIZES_WOMENS,
		styleNumber: '10096',
	},
	{
		bullets: [],
		collections: {},
		frequencies: [
			{
				key: 'monthly',
				price: DEFAULT_PRICE_LAUNDRY_SHEETS_DECIMAL_SUBSCRIPTION,
				priceRegular: DEFAULT_PRICE_LAUNDRY_SHEETS_DECIMAL,
				priceModifier: 'MO',
				title: 'Bi-Monthly Laundry Sheets Subscription',
			},
			{
				key: 'bimonthly',
				price: DEFAULT_PRICE_LAUNDRY_SHEETS_DECIMAL_SUBSCRIPTION,
				priceRegular: DEFAULT_PRICE_LAUNDRY_SHEETS_DECIMAL,
				priceModifier: 'MO',
				title: 'Bi-Monthly Laundry Sheets Subscription',
			},
			{
				key: 'quarterly',
				price: DEFAULT_PRICE_LAUNDRY_SHEETS_DECIMAL_SUBSCRIPTION,
				priceRegular: DEFAULT_PRICE_LAUNDRY_SHEETS_DECIMAL,
				priceModifier: 'MO',
				title: 'Bi-Monthly Laundry Sheets Subscription',
			},
		],
		gender: 'unisex',
		landingPage: LANDING_PAGES.laundrySheets,
		isMatching: false,
		name: 'Laundry Sheets',
		nameMedium: 'Laundry Sheets',
		nameShort: 'Laundry Sheets',
		productType: 'Laundry Detergent Sheets',
		styleNumber: '10289',
	},
	{
		bullets: [],
		collections: {
			default: 'heel-hammock-ankle-socks',
			surprise: 'heel-hammock-ankle-socks',
		},
		frequencies: [
			{
				key: 'monthly',
				price: DEFAULT_PRICE_SOCKS_DECIMAL,
				priceRegular: DEFAULT_PRICE_SOCKS_RETAIL_DECIMAL,
				priceModifier: 'MO',
				title: 'Monthly Ankle Sock Subscription',
			},
		],
		gender: 'unisex',
		img: 'https://cdn.shinesty.com/2023-11-27/ankle-outline.svg',
		isMatching: false,
		selectedImg: 'https://cdn.shinesty.com/2023-11-27/ankle-filled.svg',
		landingPage: LANDING_PAGES.socks,
		// material: '95% Modal, 5% Spandex',
		name: 'Ankle Socks',
		nameMedium: 'Ankle Socks',
		nameShort: 'Ankle Socks',
		productType: 'Socks - Ankle',
		sizes: SIZES_SOCKS,
		styleNumber: '10402',
	},
	{
		bullets: [],
		collections: {
			default: 'heel-hammock-crew-socks',
			surprise: 'heel-hammock-crew-socks',
		},
		frequencies: [
			{
				key: 'monthly',
				price: DEFAULT_PRICE_SOCKS_DECIMAL,
				priceRegular: DEFAULT_PRICE_SOCKS_RETAIL_DECIMAL,
				priceModifier: 'MO',
				title: 'Monthly Crew Sock Subscription',
			},
		],
		gender: 'unisex',
		img: 'https://cdn.shinesty.com/2023-11-27/crew-outline.svg',
		isMatching: false,
		selectedImg: 'https://cdn.shinesty.com/2023-11-27/crew-filled.svg',
		landingPage: LANDING_PAGES.socks,
		// material: '95% Modal, 5% Spandex',
		name: 'Crew Socks',
		nameMedium: 'Crew Socks',
		nameShort: 'Crew Socks',
		productType: 'Socks - Crew',
		sizes: SIZES_SOCKS,
		styleNumber: '10377',
	},
	{
		bullets: [],
		collections: {
			default: 'heel-hammock-crew-socks',
			surprise: 'heel-hammock-crew-socks',
		},

		frequencies: [
			{
				key: 'monthly',
				price: DEFAULT_PRICE_TSHIRTS_DECIMAL,
				priceRegular: DEFAULT_PRICE_TSHIRTS_RETAIL_DECIMAL,
				priceModifier: 'MO',
				title: 'Monthly T-Shirt Subscription',
			},
			{
				key: 'quarterly',
				price: DEFAULT_PRICE_TSHIRTS_DECIMAL,
				priceRegular: DEFAULT_PRICE_TSHIRTS_RETAIL_DECIMAL,
				priceModifier: 'QTR',
				title: 'Quarterly T-Shirt Subscription',
			},
		],
		gender: 'mens',
		img: 'https://cdn.shinesty.com/2023-11-27/crew-outline.svg',
		isMatching: false,
		selectedImg: 'https://cdn.shinesty.com/2023-11-27/crew-filled.svg',
		landingPage: 'https://shinesty.com/collections/mens-t-shirts', // what for this
		// material: '95% Modal, 5% Spandex',
		name: 'T-Shirts',
		nameMedium: 'T-Shirts',
		nameShort: 'T-Shirts',
		productType: "Men's T-Shirts",
		sizes: SIZES_MENS_SUBSCRIPTION_TEES,
		styleNumber: '10382',
	},
	{
		bullets: [
			'3x Softer than cotton',
			'Mid-high rise with full coverage',
			'Lightly compressive fit to prevent slipping',
		],
		careInstructions: CARE_INSTRUCTIONS_WOMENS_DEFAULT,
		collections: {
			default: 'womens-boxer-briefs',
			surprise: 'womens-boxer-briefs',
		},
		frequencies: [
			{
				key: 'annually',
				price: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_ANNUAL_DECIMAL,
				priceRegular: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_ANNUAL_RETAIL_DECIMAL,
				priceModifier: 'YR',
				title: "Annual Women's Boxer Subscription",
			},
			{
				key: 'monthly',
				price: DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_MONTHLY_DECIMAL,
				priceRegular: DEFAULT_PRICE_MENS_UNDERWEAR_DECIMAL,
				priceModifier: 'MO',
				title: "Monthly Women's Boxer Subscription",
			},
			// Removing new quarterly signups until later date per Melinda 2022-07-13
			// {
			// 	key: 'quarterly',
			// 	price: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_QUARTERLY_DECIMAL,
			// 	priceRegular: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_QUARTERLY_RETAIL_DECIMAL,
			// 	priceModifier: 'QTR',
			// 	title: 'Quarterly Bikini Subscription',
			// },
		],
		gender: 'womens',
		iconKeyOutline: 'underwearWBoxerOutline',
		iconKeyOutlineWhite: 'underwearWBoxerOutlineWhite',
		iconKeySolid: 'underwearWBoxerFilled',
		imagePlaceholderSrc: 'https://cdn.shinesty.com/2024-04-03/WBoxerIcon.png',
		imagePlaceholderWhiteSrc: 'https://cdn.shinesty.com/2024-04-03/WBoxerIcon-white.png',
		isMatching: true,
		landingPage: LANDING_PAGES.womens,
		material: '',
		name: "Women's Boxer",
		nameMedium: "Women's Boxer",
		nameShort: "Women's Boxer",
		productType: "Women's Boxer",
		sizes: SIZES_WOMENS,
		styleNumber: '',
	},
	{
		bullets: ['3x Softer than cotton', 'Mid-high rise with low coverage', 'Zero panty lines'],
		careInstructions: CARE_INSTRUCTIONS_WOMENS_DEFAULT,
		collections: {
			default: 'modal-thong-underwear-for-women',
			surprise: 'modal-thong-underwear-for-women',
		},
		frequencies: [
			// Removing new quarterly signups until later date per Anna 2024-04-22
			{
				key: 'annually',
				price: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_ANNUAL_DECIMAL,
				priceRegular: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_ANNUAL_RETAIL_DECIMAL,
				priceModifier: 'YR',
				title: 'Annual Thong Subscription',
			},
			{
				key: 'monthly',
				price: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_MONTHLY_DECIMAL,
				priceRegular: DEFAULT_PRICE_WOMENS_UNDERWEAR_DECIMAL,
				priceModifier: 'MO',
				title: 'Monthly Thong Subscription',
			},
			// Removing new quarterly signups until later date per Anna 2024-04-22
			// {
			//  key: 'quarterly',
			//  price: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_QUARTERLY_DECIMAL,
			//  priceRegular: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_QUARTERLY_RETAIL_DECIMAL,
			//  priceModifier: 'QTR',
			//  title: 'Quarterly Boyshort Subscription',
			// },
		],
		gender: 'womens',
		iconKeyOutline: 'underwearThongOutline',
		iconKeyOutlineWhite: 'underwearThongOutlineWhite',
		iconKeySolid: 'underwearThongFilled',
		imagePlaceholderSrc: 'https://cdn.shinesty.com/2022-06-21/thong-sketch.png',
		imagePlaceholderWhiteSrc: 'https://cdn.shinesty.com/2022-07-20/thong-sketch-white.png',
		isMatching: true,
		landingPage: LANDING_PAGES.womens,
		material: '95% Modal, 5% Spandex',
		name: 'Modal Thong',
		nameMedium: 'Modal Thong',
		nameShort: 'Modal Thong',
		productType: 'Thongs - Modal',
		sizes: SIZES_WOMENS,
		styleNumber: '10398',
	},
];

/**
 *
 * @param {object} settings
 * 		- defaultImage string
 * 		- expectedChildrenCount number
 * 		- firstPairTitle string
 * 		- firstPairImage string
 * 		- landingPage string
 * 		- miniCartData: array
 * 		- size string
 * 		- title string
 * 		- subscriptionId string
 * 		- subscriptionType string
 * 		- variant {}
 * @returns
 */
const generateSubscriptionParent = (settings) => {
	// attributes for the parent product (i.e. the subscription)
	let attributesParent = [
		{ key: CAN_CHANGE_QUANTITY, value: 'false' },
		{ key: HIDE_QUANTITY, value: 'true' },
		{ key: IS_HIDDEN, value: 'true' },
		{ key: IS_SUBSCRIPTION, value: 'true' },
		{ key: IS_SUBSCRIPTION_PARENT, value: 'true' },
		{ key: LINK_TO, value: settings.landingPage },
		{ key: SUBSCRIPTION_FREQUENCY, value: settings.subscriptionFrequency },
		{ key: SUBSCRIPTION_SUBTITLE, value: settings.subtitle },
		{ key: SUBSCRIPTION_TITLE, value: settings.title },
		{ key: SUBSCRIPTION_ID, value: settings.subscriptionId },
		{ key: SUBSCRIPTION_TYPE, value: settings.subscriptionType },
	];

	if (settings.defaultImage) {
		attributesParent.push({ key: DEFAULT_IMAGE, value: settings.defaultImage });
	}

	if (settings.expectedChildrenCount) {
		attributesParent.push({
			key: EXPECTED_CHILDREN_COUNT,
			value: settings.expectedChildrenCount.toString(),
		});
	}

	if (settings.firstPairImage) {
		attributesParent.push({ key: FIRST_PAIR_IMAGE, value: settings.firstPairImage });
	}

	if (settings.firstPairImages) {
		attributesParent.push({ key: FIRST_PAIR_IMAGES, value: settings.firstPairImages });
	}

	if (settings.firstPairTitle) {
		attributesParent.push({ key: FIRST_PAIR_TITLE, value: settings.firstPairTitle });
	}

	if (settings.miniCartData) {
		attributesParent.push({ key: MINI_CART_DATA, value: JSON.stringify(settings.miniCartData) });
	}

	return {
		attributes: attributesParent,
		merchandiseId: settings.variantId,
		quantity: 1,
	};
};
/**
 *
 * @param {array} frequencies frequencies from a subscription
 * @returns an updated frequencies array with auxillary data
 */
const getAuxillaryFrequencyData = (frequencies) => {
	return frequencies.map((frequency) => {
		const auxillaryData = FREQUENCIES.find((auxillary) => {
			return auxillary.key === frequency.key;
		});

		return {
			...frequency,
			...auxillaryData,
		};
	});
};

const getFirstPairImage = (productImage, defaultImage) => {
	return productImage || defaultImage;
};

const getParentVariantBySubscriptionTypeAndPrice = (subscriptionType, price) => {
	if (typeof price !== 'string') {
		price = formatMonetaryValue(price);
	}

	price = price.replace('.', '');

	const key = `${subscriptionType}${price}`;

	const parentVariant = PARENT_VARIANTS.find((variant) => {
		return variant.key === key;
	});

	if (!parentVariant) {
		console.log(`Could not find parent variant for key ${key}`);
	}

	return parentVariant;
};

const getCouplesSubscriptionAnalyticsData = (product) => {
	const parentVariant = getParentVariantBySubscriptionTypeAndPrice('couples', '2198');

	return {
		handle: 'couples-underwear-subscription',
		id: parentVariant?.productId,
		images: product.images,
		prices: [21.98],
		productType: product.productType,
		quantity: 1,
		title: product.title,
		variants: [
			{
				id: parentVariant?.id,
				sku: parentVariant?.sku,
				title: 'Couples Matching Underwear Subscription',
				quantityAvailable: 0, // TODO: Do we want the product variant inventory or 0 (subscription variant inventory)?
			},
		],
		vendor: 'Private Label',
	};
};

function getCutIcon(productType) {
	const subscription = SUBSCRIPTIONS.find((sub) => {
		return sub.productType === productType;
	});

	return {
		iconKeySolid: subscription?.iconKeySolid,
		iconKeyOutline: subscription?.iconKeyOutline,
	};
}

const getSubscriptionAnalyticsData = (frequency, product, productType, subscriptionType) => {
	/* subscription analytics - get the frequency and parent variant to pass to analytics partners */
	let subscriptionFrequency = getSubscriptionFrequencyForProductTypeAndFrequencyKey(
		productType,
		frequency,
	);

	if (!subscriptionFrequency) {
		return;
	}

	const subscriptionParentVariant = getParentVariantBySubscriptionTypeAndPrice(
		subscriptionType,
		subscriptionFrequency.price,
	);

	if (!subscriptionParentVariant) {
		console.log('no subscription parent variant');
		return;
	}

	return {
		handle: product.handle,
		id: subscriptionParentVariant.productId,
		image: product.images[0].src,
		isSubscriptionParent: true,
		prices: [subscriptionFrequency.price],
		productType: 'Subscription',
		quantity: 1,
		title: product.title,
		variants: [
			{
				id: subscriptionParentVariant.id,
				sku: subscriptionParentVariant.sku,
				title: subscriptionFrequency.title,
				quantityAvailable: 0, // TODO: Do we want the product variant inventory or 0 (subscription variant inventory)?
			},
		],
		vendor: 'Private Label',
	};
};

const getSubscriptionConfigForGender = (gender) => {
	return SUBSCRIPTION_CONFIGS.find((subscriptionConfig) => {
		return subscriptionConfig.gender === gender;
	});
};

const getSubscriptionsForGender = (gender, isMatching) => {
	let subscriptions = SUBSCRIPTIONS.filter((subscription) => {
		if (typeof isMatching !== 'undefined') {
			return subscription.gender === gender && subscription.isMatching === isMatching;
		}
		return subscription.gender === gender;
	});

	subscriptions = subscriptions.map((subscription) => {
		return populateSubscriptionVariants(subscription);
	});

	const sortLookup = {
		mens: [
			'Boxers',
			'Boxers - Fly',
			'Boxers - Long - Fly',
			'Boxers - Brief',
			'Boxers - Trunk',
			'Boys Underwear',
		],
		womens: ['Thongs - Modal', 'Cheeky', 'Boyshort', 'Bikini', 'Thongs', "Women's Boxer"],
	};

	return sortByOrder(subscriptions, sortLookup[gender], 'productType');
};

const getSubscriptionsForLandingPage = (landingPage) => {
	let subscriptions = SUBSCRIPTIONS.filter((subscription) => {
		return subscription.landingPage === landingPage;
	});

	subscriptions = subscriptions.map((subscription) => {
		return populateSubscriptionVariants(subscription);
	});

	const sortLookup = {
		'/products/monthly-boxer-subscription': [
			'Boxers',
			'Boxers - Fly',
			'Boxers - Long - Fly',
			'Boxers - Brief',
			'Boxers - Trunk',
		],
		'/products/monthly-thong-subscription': [
			'Thongs',
			'Cheeky',
			'Bikini',
			'Boyshort',
			"Women's Boxer",
			'Thongs - Modal',
		],
		'/products/sock-subscription': ['Socks - Crew', 'Socks - Ankle'],
	};

	return sortByOrder(subscriptions, sortLookup[landingPage], 'productType');
};

const getSubscriptionForProductType = (productType) => {
	const subscription = SUBSCRIPTIONS.find((subscription) => {
		return subscription.productType.toLowerCase() === productType?.toLowerCase();
	});

	const populated = populateSubscriptionVariants(subscription);

	return populated;
};

const getSubscriptionFrequencyForProductTypeAndFrequencyKey = (productType, frequencyKey) => {
	const subscription = getSubscriptionForProductType(productType);

	const subscriptionFrequency = subscription?.frequencies.find((f) => {
		return f.key === frequencyKey;
	});

	return subscriptionFrequency;
};

/**
 * @param {object} settings
 * 		- {array} attributes - an array of {key: '', value: ''} to add to the product line attributes.
 * 		- {class} discountCodeStore
 * 		- {string} frequencyKey - 'monthly', 'quarterly', 'annually'
 * 		- {array} selections - [{ image: '', productType: '', size: '', title: '', variantId: '' }]
 * 		- {string} subscriptionType - 'single', 'couples'
 * @returns valid shopify add to cart line object
 */
const getSubscriptionData = (settings) => {
	let analytics = [];
	const firstPairImages = [];
	const isCouplesSubscription = settings.subscriptionType === 'couples';
	const isQuarterlySubscription = settings.frequencyKey === 'quarterly';
	let miniCartData = [];
	let price = 0;
	let subscriptionDefaultImage;
	let subscriptionFirstPairImage;
	const subscriptionId = uuidv4();
	const subscriptionLines = [];
	const subtitles = [];

	let matchingId;

	if (isCouplesSubscription || isQuarterlySubscription) {
		matchingId = uuidv4();
	}

	let discountCodeSubPrice;

	if (settings.discountCodeStore) {
		const allProductTypes = settings.selections.map((selection) => {
			return selection.productType;
		});

		discountCodeSubPrice = settings.discountCodeStore.getCurrentDiscountFirstMonthPrice(
			settings.frequencyKey,
			allProductTypes,
			1,
		);
	}

	settings.selections.forEach((selection, i) => {
		const subscriptionData = getSubscriptionForProductType(selection.productType);
		const parentRequired = false;

		if (!subscriptionData) {
			console.log(`no subscription data found for ${selection.productType}`);
			return;
		}

		const subscriptionFrequency = subscriptionData?.frequencies.find((f) => {
			return f.key === settings.frequencyKey;
		});

		const masterFrequency = FREQUENCIES.find((f) => {
			return f.key === settings.frequencyKey;
		});

		if (!subscriptionFrequency || !masterFrequency) {
			console.log(`could not find frequencies for ${settings.frequencyKey}`);
			return;
		}

		// attributes for the actual product (i.e. the boxer)
		let attributesChild = [
			{ key: CAN_CHANGE_QUANTITY, value: 'false' },
			{ key: HIDE_QUANTITY, value: 'true' },
			{ key: IS_SUBSCRIPTION, value: 'true' },
			{ key: IS_SUBSCRIPTION_CHILD, value: 'true' },
			{ key: SUBSCRIPTION_ID, value: subscriptionId },
			{ key: SUBSCRIPTION_SUBTITLE, value: `Size: ${selection.size}` },
		];

		if (settings.discountedDisplayPrice) {
			attributesChild.push({
				key: DISCOUNTED_LINE_PRICE,
				value: settings.discountedDisplayPrice,
			});
		}

		if (typeof discountCodeSubPrice === 'number') {
			attributesChild.push({
				key: DISCOUNTED_LINE_PRICE,
				value: discountCodeSubPrice.toString(),
			});
		} else if (settings.discountedSubPrice) {
			attributesChild.push({ key: DISCOUNTED_LINE_PRICE, value: settings.discountedSubPrice });
		}

		if (settings.additionalAttributes) {
			attributesChild.push(settings.additionalAttributes);
		}

		if (settings.frequencyKey === 'annually') {
			let annualValue = ' ';
			if (settings.selections.length > 0 && settings.selections[0].productType.includes('Boxer')) {
				annualValue = '336';
			} else {
				annualValue = '132';
			}
			attributesChild.push({ key: PREPAID_TYPE, value: 'Annually' });
			attributesChild.push({ key: ORIGINAL_PRICE, value: annualValue });
		}

		if (isQuarterlySubscription) {
			attributesChild.push({ key: PREPAID_TYPE, value: 'Quarterly' });
		}

		if (selection.sellingPlan) {
			attributesChild.push({
				key: SUBSCRIPTION_PRICE,
				value: selection.sellingPlan.recurringPrice,
			});
			attributesChild.push({ key: SUBSCRIPTION_TITLE, value: selection.sellingPlan.cartText });
			attributesChild.push({ key: FIRST_PAIR_TITLE, value: selection.title });
			attributesChild.push({ key: IS_SUBSCRIPTION, value: 'true' });
		}

		if (settings.attributes) {
			attributesChild = [...attributesChild, ...settings.attributes];
		}

		// matching id
		if (matchingId) {
			attributesChild.push({
				key: isQuarterlySubscription ? 'Quarterly UUID' : 'Matching UUID',
				value: matchingId,
			});
		}

		subscriptionLines.push({
			attributes: attributesChild,
			merchandiseId: selection.variantId,
			quantity: 1,
			sellingPlanId:
				selection.sellingPlanId ||
				selection.sellingPlan?.id ||
				settings.sellingPlanId ||
				masterFrequency.sellingPlan,
		});

		const firstPairImage = getFirstPairImage(selection.image, subscriptionData.imagePlaceholderSrc);

		//
		// short circuit the parent for couples or quarterly subscription, we'll add it after.
		//
		if ((isCouplesSubscription || isQuarterlySubscription) && parentRequired) {
			firstPairImages.push(firstPairImage);
			subtitles.push(`${subscriptionData.nameMedium} ${selection.size}`);

			// couples subscriptions are two seperate subscriptions
			if (isCouplesSubscription) {
				price += subscriptionFrequency.price;
			}

			// this is the 'child' product (the real physical product)
			analytics.push({
				brand: 'Private Label',
				category: selection.productType,
				id: selection.variantSku,
				image: selection.image,
				inventory: '0',
				name: selection.title,
				price: 0, // subscriptionFrequency.price,
				product_id: selection.product_id,
				product: {
					title: selection.title,
					images: [{ src: selection.image }],
					productType: selection.productType,
					vendor: 'Private Label',
					id: selection.product_id,
				},
				variant: selection.size,
				variant_id: selection.variantId,
				variantId: selection.variantId,
				vueInfo: settings.vueInfo,
			});

			if (i === 0) {
				subscriptionDefaultImage = subscriptionData.imagePlaceholderSrc;
				subscriptionFirstPairImage = firstPairImage;

				if (isQuarterlySubscription) {
					price = subscriptionFrequency.price;

					const parentVariant = getParentVariantBySubscriptionTypeAndPrice('single', price);

					const quarterlySubscriptionParent = generateSubscriptionParent({
						defaultImage: subscriptionDefaultImage,
						expectedChildrenCount: 3,
						landingPage: '/products/monthly-boxer-subscription',
						miniCartData,
						title: 'Quarterly Underwear Subscription',
						subscriptionFrequency: settings.frequencyKey,
						subscriptionId: subscriptionId,
						subscriptionType: settings.subscriptionType,
						subtitle: subtitles.join(', '),
						variantId: parentVariant?.id,
					});

					subscriptionLines.push(quarterlySubscriptionParent);

					// this is the 'parent' product - the subscription product
					analytics.push({
						brand: 'Private Label',
						category: 'Subscription',
						id: parentVariant?.sku,
						image: subscriptionDefaultImage,
						inventory: '0',
						price: price, // subscriptionFrequency.price,
						product_id: parentVariant?.productId,
						product: {
							title: parentVariant?.title,
							images: [subscriptionDefaultImage],
							productType: 'Subscription',
							vendor: 'Private Label',
							id: parentVariant?.productId,
						},
						variant: parentVariant?.title,
						variant_id: parentVariant?.id,
						variantId: parentVariant?.id,
						quantity: 1,
					});
				}
			}

			miniCartData.push({
				image: selection.image,
				productType: subscriptionData.nameMedium,
				size: selection.size,
			});

			return;
		}
		// this is the 'child' product (the real physical product)
		analytics.push({
			brand: 'Private Label',
			category: selection.productType,
			id: selection.variantSku,
			image: selection.image,
			inventory: '0',
			name: selection.title,
			price: 0, // subscriptionFrequency.price,
			product_id: selection.product_id,
			product: {
				title: selection.title,
				images: [{ src: selection.image }],
				productType: selection.productType,
				vendor: 'Private Label',
				id: selection.product_id,
			},
			variant: selection.size,
			variant_id: selection.variantId,
			variantId: selection.variantId,
			vueInfo: settings.vueInfo,
		});

		if (parentRequired) {
			// this is the 'parent' product - the subscription product
			analytics.push({
				brand: 'Private Label',
				category: 'Subscription',
				id: subscriptionFrequency.variant?.sku || 'no-sku',
				image: subscriptionData.imagePlaceholderSrc,
				inventory: '0',
				price: subscriptionFrequency.price,
				product_id: subscriptionFrequency.variant?.productId || 'no-product-id',
				product: {
					title: subscriptionFrequency.title,
					images: [subscriptionData.imagePlaceholderSrc],
					productType: 'Subscription',
					vendor: 'Private Label',
					id: subscriptionFrequency.variant?.productId || 'no-product-id',
				},
				variant: subscriptionFrequency.title,
				variant_id: subscriptionFrequency.variant?.id || 'no-variant-id',
				variantId: subscriptionFrequency.variant?.id || 'no-variant-id',
				quantity: 1,
			});

			const singleSubscriptionParent = generateSubscriptionParent({
				defaultImage: subscriptionData.imagePlaceholderSrc,
				expectedChildrenCount: 1,
				firstPairImage: firstPairImage,
				firstPairTitle: selection.title,
				landingPage: subscriptionData.landingPage,
				title: subscriptionFrequency.title,
				subscriptionFrequency: settings.frequencyKey,
				subscriptionId: subscriptionId,
				subscriptionType: settings.subscriptionType,
				subtitle: `Size: ${selection.size}`,
				variantId: subscriptionFrequency.variant?.id || 'no-variant-id',
			});

			subscriptionLines.push(singleSubscriptionParent);
		}
	});

	// if (isQuarterlySubscription) {

	// }

	if (isCouplesSubscription) {
		const parentVariant = getParentVariantBySubscriptionTypeAndPrice('couples', price);

		if (parentVariant) {
			const firstPairTitle = settings.selections[0].title;

			const couplesSubscriptionParent = generateSubscriptionParent({
				defaultImage: subscriptionDefaultImage,
				expectedChildrenCount: 2,
				firstPairImage: subscriptionFirstPairImage,
				firstPairTitle: firstPairTitle || 'Matching Underwear Subscription',
				landingPage: '/products/couples-underwear-subscription',
				miniCartData,
				title: 'Matching Underwear Subscription',
				subscriptionFrequency: settings.frequencyKey,
				subscriptionId: subscriptionId,
				subscriptionType: settings.subscriptionType,
				subtitle: subtitles.join(', '),
				variantId: parentVariant?.id,
			});

			subscriptionLines.push(couplesSubscriptionParent);

			// this is the 'parent' product - the subscription product
			analytics.push({
				brand: 'Private Label',
				category: 'Subscription',
				id: parentVariant?.sku,
				image: subscriptionDefaultImage,
				inventory: '0',
				price: price, // subscriptionFrequency.price,
				product_id: parentVariant?.productId,
				product: {
					title: parentVariant?.title,
					images: [subscriptionDefaultImage],
					productType: 'Subscription',
					vendor: 'Private Label',
					id: parentVariant?.productId,
				},
				variant: parentVariant?.title,
				variant_id: parentVariant?.id,
				variantId: parentVariant?.id,
				quantity: 1,
			});
		}
	}

	return {
		analytics,
		lineItems: subscriptionLines,
	};
};

const populateSubscriptionVariants = (subscription) => {
	if (!subscription || subscription.frequencies.length === 0) {
		return subscription;
	}

	subscription.frequencies = subscription.frequencies.map((frequency) => {
		frequency.variant = getParentVariantBySubscriptionTypeAndPrice('single', frequency.price);
		return frequency;
	});

	return subscription;
};

module.exports = {
	SUBSCRIPTIONS,
	getAuxillaryFrequencyData,
	getCouplesSubscriptionAnalyticsData,
	getCutIcon,
	getSubscriptionAnalyticsData,
	getSubscriptionConfigForGender,
	getSubscriptionData,
	getSubscriptionsForLandingPage,
	getSubscriptionForProductType,
	getSubscriptionsForGender,
	getSubscriptionFrequencyForProductTypeAndFrequencyKey,
};
