// mens underwear pricing
const DEFAULT_PRICE_MENS_BOXER_COOLING = 3499;
const DEFAULT_PRICE_MENS_BOXER_COOLING_DECIMAL = 34.99;
const DEFAULT_PRICE_MENS_UNDERWEAR = 2999;
const DEFAULT_PRICE_MENS_UNDERWEAR_DECIMAL = 29.99;
const DEFAULT_PRICE_MENS_UNDERWEAR_LONG_DECIMAL = 29.99;
const DEFAULT_PRICE_BOYS_UNDERWEAR_DECIMAL = 19.99;

// mens subscription pricing
const DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_ANNUAL_DECIMAL = 175.99;
const DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_ANNUAL_RETAIL_DECIMAL = 335.88;
const DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_LONG_ANNUAL_RETAIL_DECIMAL = 359.88;
const DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_MONTHLY_DECIMAL = 19.99;
const DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_QUARTERLY_DECIMAL = 47.97;
const DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_LONG_QUARTERLY_RETAIL_DECIMAL = 89.97;
const DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_QUARTERLY_RETAIL_DECIMAL = 83.97;
const DEFAULT_PRICE_SUBSCRIPTION_BOYS_UNDERWEAR_MONTHLY_DECIMAL = 14.99;
const DEFAULT_PRICE_SUBSCRIPTION_BOYS_UNDERWEAR_ANNUAL_DECIMAL = 14.99;

// womens subscription pricing
const DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_ANNUAL_DECIMAL = 156.96;
const DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_ANNUAL_RETAIL_DECIMAL = 239.88;
const DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_MONTHLY_DECIMAL = 15.99;
const DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_QUARTERLY_DECIMAL = 34.98;
const DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_QUARTERLY_RETAIL_DECIMAL = 59.97;

// womens subscription thong pricing
const DEFAULT_PRICE_SUBSCRIPTION_WOMENS_THONG_ANNUAL_DECIMAL = 109.99;
const DEFAULT_PRICE_SUBSCRIPTION_WOMENS_THONG_ANNUAL_RETAIL_DECIMAL = 203.88;
const DEFAULT_PRICE_SUBSCRIPTION_WOMENS_THONG_MONTHLY_DECIMAL = 10.99;
const DEFAULT_PRICE_SUBSCRIPTION_WOMENS_THONG_QUARTERLY_DECIMAL = 28.98;
const DEFAULT_PRICE_SUBSCRIPTION_WOMENS_THONG_QUARTERLY_RETAIL_DECIMAL = 50.97;

// womens underwear pricing
const DEFAULT_PRICE_WOMENS_THONG_COOLING = 2499;
const DEFAULT_PRICE_WOMENS_THONG_COOLING_DECIMAL = 24.99;
const DEFAULT_PRICE_WOMENS_UNDERWEAR = 1999;
const DEFAULT_PRICE_WOMENS_UNDERWEAR_DECIMAL = 19.99;
const DEFAULT_PRICE_WOMENS_THONG = 1699;
const DEFAULT_PRICE_WOMENS_THONG_DECIMAL = 16.99;

// laundry sheets pricing
const DEFAULT_PRICE_LAUNDRY_SHEETS = 1599;
const DEFAULT_PRICE_LAUNDRY_SHEETS_DECIMAL = 15.99;
const DEFAULT_PRICE_LAUNDRY_SHEETS_SUBSCRIPTION = 1199;
const DEFAULT_PRICE_LAUNDRY_SHEETS_DECIMAL_SUBSCRIPTION = 11.99;
// socks sub pricing
const DEFAULT_PRICE_SOCKS = 999;
const DEFAULT_PRICE_SOCKS_DECIMAL = 9.99;
const DEFAULT_PRICE_SOCKS_RETAIL_DECIMAL = 13.99;

// T shirt sub pricing
const DEFAULT_PRICE_TSHIRTS = 19.99;
const DEFAULT_PRICE_TSHIRTS_DECIMAL = 19.99;
const DEFAULT_PRICE_TSHIRTS_RETAIL_DECIMAL = 44.99;

module.exports = {
	DEFAULT_PRICE_SUBSCRIPTION_BOYS_UNDERWEAR_ANNUAL_DECIMAL,
	DEFAULT_PRICE_BOYS_UNDERWEAR_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_BOYS_UNDERWEAR_MONTHLY_DECIMAL,
	DEFAULT_PRICE_LAUNDRY_SHEETS,
	DEFAULT_PRICE_LAUNDRY_SHEETS_DECIMAL,
	DEFAULT_PRICE_LAUNDRY_SHEETS_DECIMAL_SUBSCRIPTION,
	DEFAULT_PRICE_LAUNDRY_SHEETS_SUBSCRIPTION,
	DEFAULT_PRICE_MENS_BOXER_COOLING,
	DEFAULT_PRICE_MENS_BOXER_COOLING_DECIMAL,
	DEFAULT_PRICE_MENS_UNDERWEAR,
	DEFAULT_PRICE_MENS_UNDERWEAR_DECIMAL,
	DEFAULT_PRICE_MENS_UNDERWEAR_LONG_DECIMAL,
	DEFAULT_PRICE_SOCKS,
	DEFAULT_PRICE_SOCKS_DECIMAL,
	DEFAULT_PRICE_SOCKS_RETAIL_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_ANNUAL_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_ANNUAL_RETAIL_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_LONG_ANNUAL_RETAIL_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_MONTHLY_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_QUARTERLY_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_QUARTERLY_RETAIL_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_WOMENS_THONG_ANNUAL_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_WOMENS_THONG_ANNUAL_RETAIL_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_WOMENS_THONG_MONTHLY_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_WOMENS_THONG_QUARTERLY_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_WOMENS_THONG_QUARTERLY_RETAIL_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_ANNUAL_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_ANNUAL_RETAIL_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_MONTHLY_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_QUARTERLY_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_LONG_QUARTERLY_RETAIL_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_WOMENS_UNDERWEAR_QUARTERLY_RETAIL_DECIMAL,
	DEFAULT_PRICE_TSHIRTS,
	DEFAULT_PRICE_TSHIRTS_DECIMAL,
	DEFAULT_PRICE_TSHIRTS_RETAIL_DECIMAL,
	DEFAULT_PRICE_WOMENS_THONG_COOLING,
	DEFAULT_PRICE_WOMENS_THONG_COOLING_DECIMAL,
	DEFAULT_PRICE_WOMENS_THONG,
	DEFAULT_PRICE_WOMENS_THONG_DECIMAL,
	DEFAULT_PRICE_WOMENS_UNDERWEAR,
	DEFAULT_PRICE_WOMENS_UNDERWEAR_DECIMAL,
};
